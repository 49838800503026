@media only screen and (max-width: 600px) {
  .content {
    padding: 5px !important;
  }
  span.lg {
    font-size: 12pt!important;
  }
}
.content {
  /*background-color: grey;*/
  padding: 100px 50px;
  
  display: grid;
  grid-template-areas: "hd-1 hd-2 hd-3" "body body body";
  grid-template-columns: auto 10fr auto;
  grid-template-rows: auto 1fr;
  gap: 5px;
}
.content > div {
  border-radius: 5px;
  padding: 5px;
  background-color: lightgray;
}
.content > div > span {
  vertical-align: middle;
}


.create-party-label {
  grid-area: hd-3;
  font-size: 16pt;
  border-radius: 20px;
  cursor: pointer;
  background-color: darkgray!important ;
}

.select-party-label {
  grid-area: hd-1;
  font-size: 16pt;
  border-radius: 20px;
}

.select-party-box {
  overflow: scroll;
  grid-area: hd-2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.select-party {
  background-color: darkgray;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  user-select: none;
  font-size: 16pt;
}
.select-party.selected {
  background-color: rgb(255, 189, 67);
}
.select-party > span {
  color: white;
}

.party-box {
  grid-area: body;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px !important;
  gap: 5px;
}

.guests {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px 0px;
  overflow: auto;
}
.guests .header {
  background-color: unset!important;
  justify-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirmations > span:not(:last-child) {
  margin-right: 5px;
}
.confirmations > span:nth-child(1) {
  color: green;
  font-weight: bold;
}
.confirmations > span:nth-child(2) {
  color: red;
  font-weight: bold;
}
.confirmations > span:nth-child(3) {
  color: black;
  font-weight: bold;
}
span.lg {
  font-size: 18pt;
}
.guests > div {
  background-color: lightgrey!important ;
  padding: 8px;
  cursor: pointer;
  overflow: auto;
}
.guests>div:nth-child(8n+5),
.guests>div:nth-child(8n+6),
.guests>div:nth-child(8n+7),
.guests>div:nth-child(8n+8) {
  background-color: #c5c5c5!important;
}
.guests>div:nth-child(8n+9),
.guests>div:nth-child(8n+10),
.guests>div:nth-child(8n+11),
.guests>div:nth-child(8n+12) {
  background-color: #dedede!important;
;

}

.partyname {
  font-size: 24pt;
}

dialog {
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px;
}

dialog>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
dialog > div label {
  margin-right: 5px;
}
#backdrop {
  background-color: black;
  opacity: 0.3;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left:0px;
}
.dialog-person-table {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
}
.dialog-party-table {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr auto 1fr auto;
}
.add-guest-button {
  padding: 5px;
  border-radius: 4px;
  background-color: darkgray;
  cursor: pointer;
}
.add-guest {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.add-guest > div {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 7px;
  max-height: calc(3px + 1rem);
}
.danger-hint {
  align-self: flex-end;
  font-size: 8pt;
  color: red;
  flex-wrap:nowrap;
}
.danger-hint > pre {
  display: inline-block;
}
#template {
  min-height: 1rem;
}
.tip {
  height: 1rem;
  align-self: flex-end;
  font-size: 8pt;
  color: gray;
}
.dialog-bottom {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  width:100%;
}

.coming-yes {
  color: green;
  font-weight: bold;
}
.coming-no {
  color: red;
}
.coming-maybe {
  font-style: italic;
  color: blue;
}